import "./Footer.scss";

export function Footer() {
  return (
    <footer className="footer">
      <div className="footerWrapper section background">
        <p className="raleway fw400 balance">Copyright &nbsp;©&nbsp; 2024 Harvestmen Music - All Rights Reserved</p>
      </div>
    </footer>
  );
}
